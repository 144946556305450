import { useEffect, useRef, useState } from "react";
import GuestLayout from "@/Layouts/GuestLayout";
import { Head, Link, useForm } from "@inertiajs/react";
import TextField from "@/Components/TextField";
import ApplicationLogo from "@/Components/ApplicationLogo";
import messageIcon from "../../../../images/Message_icon.svg";
import lockIcon from "../../../../images/Lock_icon.svg";
import eyeOffIcon from "../../../../images/icon_Eyeoff.svg";
import eyeOnIcon from "../../../../images/icon_Eyeon.svg";
import mobileOTPIcon from "../../../../images/Mobile_OTP.svg";
import Button from "@/Components/Button";
import { Strings } from "@/resources";
import ReCAPTCHA from "react-google-recaptcha";
import { getSpecificHumanReadableTime } from "@/utils/time";

const Login = ({
  otpSent,
  showIPCaptcha,
  showAccountCaptcha,
  app_version_number,
}: {
  success?: string;
  status?: string;
  error?: string;
  otpSent: boolean;
  uid?: string;
  showIPCaptcha: boolean;
  showAccountCaptcha: boolean;
  app_version_number?: string;
}) => {
  const recaptchaRef: any = useRef();
  const { data, setData, post, processing, errors, reset } = useForm({
    email: "",
    password: "",
    otp: "",
    otpSent: otpSent,
    remember: false,
    account_throttle: "",
    ip_throttle: "",
    recaptcha_token: "",
  });
  let timer: any;
  const [counter, setCounter] = useState(90);

  const [accountThrottle, setAccountThrottle] = useState(0);
  const [accountaThrottleHumanTime, setAccountThrottleHumanTime] = useState("");

  const [ipThrottle, setIpThrottle] = useState(0);
  const [ipThrottleHumanTime, setIpThrottleHumanTime] = useState("");

  const updateThrottleTime = () => {
    setAccountThrottle((prevThrottle) => {
      // Check if the previous throttle value is greater than 0
      if (prevThrottle > 0) {
        const newThrottle = prevThrottle - 1;
        // Update the human readable time only if the new throttle value is not 0
        setAccountThrottleHumanTime(getSpecificHumanReadableTime(newThrottle));
        return newThrottle;
      }
      // If the previous throttle value is 0, return it without changes
      return prevThrottle;
    });

    setIpThrottle((prevThrottle) => {
      // Check if the previous throttle value is greater than 0
      if (prevThrottle > 0) {
        const newThrottle = prevThrottle - 1;
        // Update the human readable time only if the new throttle value is not 0
        setIpThrottleHumanTime(getSpecificHumanReadableTime(newThrottle));
        return newThrottle;
      }
      // If the previous throttle value is 0, return it without changes
      return prevThrottle;
    });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      updateThrottleTime();
    }, 1000);
    return () => {
      reset("password");
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    setData("otpSent", otpSent);
  }, [otpSent]);

  const submit: any = (e: any, resend = false) => {
    e.preventDefault();

    if (resend) {
      post(route("resend-otp"), {
        onSuccess: () => {
          setCounter(90);
        },
      });
    } else {
      post(route("login"), {
        onSuccess: () => {
          setCounter(90);
          recaptchaRef?.current && recaptchaRef?.current?.reset();
        },
        onError: (error) => {
          if (error?.account_throttle) {
            setAccountThrottle(Number(error?.account_throttle));
            setAccountThrottleHumanTime("");
          }
          if (error?.ip_throttle) {
            setIpThrottle(Number(error?.ip_throttle));
            setIpThrottleHumanTime("");
          }
          recaptchaRef?.current && recaptchaRef?.current?.reset();
        },
      });
    }
  };

  function recaptchaUpdated(value: any) {
    setData("recaptcha_token", value);
  }

  const renderCaptcha = () => {
    if (!showIPCaptcha && !showAccountCaptcha) return null;

    return (
      <div className="captcha-con">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={import.meta.env.VITE_CAPTCHA_KEY}
          onErrored={() => setData("recaptcha_token", "")}
          onChange={recaptchaUpdated}
        />
      </div>
    );
  };

  const renderCaptchaError = () => {
    if (!errors.recaptcha_token) return null;
    return <div className="invalid-attempt show">{errors.recaptcha_token}</div>;
  };

  const renderIPThrottleError = () => {
    if (!ipThrottle || ipThrottleHumanTime === "") return null;
    return (
      <div className="invalid-attempt show">
        IP blocked, Please retry in {ipThrottleHumanTime}
        {"."}
      </div>
    );
  };
  const renderAccountThrottleError = () => {
    if (!accountThrottle || accountaThrottleHumanTime === "") return null;
    return (
      <div className="invalid-attempt show">
        Account blocked, Please retry in {accountaThrottleHumanTime}
        {"."}
      </div>
    );
  };

  const renderOTP = () => {
    return (
      <div className="form-fields">
        <Head title={Strings.otp_verification} />
        <h6 className="form-title">{Strings.otp_verification}</h6>
        <div className="input-group-wrapper">
          <div className="input-field full-column">
            <TextField
              type="text"
              name="otp"
              value={data.otp}
              label={Strings.login_otp.label}
              placeholder={Strings.login_otp.placeholder}
              leftIcon={mobileOTPIcon}
              isFocused={true}
              autoFocus={true}
              error={errors.otp}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setData("otp", e.target.value)
              }
            />
            <div className="timer">
              <p>
                {Strings.time_remaining}:{" "}
                <span className="countdown">
                  {counter} {Strings.seconds}
                </span>
              </p>
              <a
                href="#"
                onClick={(e) => {
                  counter === 0 && !processing && submit(e, true);
                }}
                className={`${counter === 0 ? "" : "disabled"}`}
              >
                {Strings.resend_otp}
              </a>
            </div>
          </div>

          <div className="form-btn two-flex-btns">
            <Button
              className="btn btn-white"
              type="reset"
              onClick={(e) => {
                e.preventDefault();
                window.location.reload();
              }}
            >
              {Strings.cancel}
            </Button>
            <Button
              className="btn"
              type="submit"
              disabled={processing}
              loading={processing}
            >
              {Strings.verify}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderVersionNumber = () => {
    return (
      <div className='version-number'>
        <span className='version-number-text'>
          {app_version_number}
        </span>
      </div>
    )
  }

  const renderLogin = () => {
    return (
      <div className="form-fields">
        <Head title={Strings.login} />
        <h6 className="form-title">{Strings.login_upper}</h6>
        <div className="input-group-wrapper">
          <div className="input-field full-column">
            <TextField
              type="email"
              name="email"
              value={data.email}
              label={Strings.login_email.label}
              placeholder={Strings.login_email.placeholder}
              isFocused={true}
              autoFocus={true}
              leftIcon={messageIcon}
              error={errors.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setData("email", e.target.value)
              }
            />
          </div>

          <div className="input-field full-column icon-padding">
            <TextField
              type="password"
              name="password"
              value={data.password}
              label={Strings.login_password.label}
              placeholder={Strings.login_password.placeholder}
              leftIcon={lockIcon}
              rightIcon={eyeOffIcon}
              rightIcon2={eyeOnIcon}
              error={errors.password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setData("password", e.target.value)
              }
            />
            <Link
              href={route("password.request")}
              tabIndex={-1}
              className="forget-pass"
            >
              {Strings.forgot_password}
            </Link>
          </div>
          {renderCaptcha()}
          {renderCaptchaError()}
          {renderIPThrottleError()}
          {renderAccountThrottleError()}
          <div className="form-btn">
            <Button
              type="submit"
              disabled={processing || ipThrottle > 0 || accountThrottle > 0}
              loading={processing}
            >
              {Strings.login}
            </Button>
          </div>
          {app_version_number ? renderVersionNumber() : null}
        </div>
      </div>
    );
  };

  return (
    <>
      <main className="form-screens">
        <form onSubmit={submit} className="login-form">
          <div className="form-container">
            <div className="form-logo">
              <ApplicationLogo className="img-fluid" />
            </div>

            {otpSent ? renderOTP() : renderLogin()}
          </div>
        </form>
      </main>
    </>
  );
};

Login.layout = (page: any) => (
  <GuestLayout
    children={page}
    success={page.props.success}
    error={page.props.error}
    uid={page.props.uid}
  />
);

export default Login;
